import React from 'react'
import './LegalNotice.css';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const Terms = () => {
    const { t } = useTranslation();

    document.title = t("page-title.terms");

    return (
        <div className="UILegalNotice">
            <div className="container-fluid background">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-7">
                            <div className="heading">
                                <h1>{t('pages.terms')}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container content">
                <div className="text">
                    <h4>{t('terms.heading1')}</h4>
                    <p>{t('terms.text1')}</p>
                    
                    <h4>{t('terms.heading2')}</h4>
                    <p>{t('terms.text2')}</p>

                    <h4>{t('terms.heading3')}</h4>
                    <p>{t('terms.text3')}</p>

                    <h4>{t('terms.heading4')}</h4>
                    <p>{t('terms.text4')}</p>

                    <h4>{t('terms.heading5')}</h4>
                    <p>{t('terms.text5')}</p>
                </div>
            </div>
        </div>
    )
}

export default Terms