import React from "react";
import "./LegalNotice.css";

import { useTranslation } from "react-i18next";
import i18n from "i18next";

const LegalNotice = () => {
  const { t } = useTranslation();

  document.title = t("page-title.legalnotice");

  var lang = i18n.language;

  return (
    <div className="UILegalNotice">
      <div className="container-fluid background">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-7">
              <div className="heading">
                <h1>{t("pages.legalnotice")}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container content">
        <div className="text">
          {lang.includes("_at") && (
            <div>
              <h4>{t("privacy.heading0")}</h4>
              <p>
                {t("privacy.text0-1")}
                <br></br>
                {t("privacy.text0-2")}
                <br></br>
                {t("privacy.text0-3")}
                <br></br>
                {t("privacy.text0-4")}
              </p>
            </div>
          )}

          {lang.includes("_de") && (
            <div>
              <h4>{t("privacy.heading0")}</h4>
              <p>
                {t("privacy.text0-1")}
                <br></br>
                {t("privacy.text0-2")}
                <br></br>
                {t("privacy.text0-3")}
                <br></br>
                {t("privacy.text0-4")}
              </p>
            </div>
          )}

          <h4>{t("privacy.heading1")}</h4>
          <p>{t("privacy.text1")}</p>
          <h4>{t("privacy.heading2")}</h4>
          <p>{t("privacy.text2")}</p>

          {lang.includes("_at") && (
            <div>
              <p>{t("privacy.text2-1")}</p>
              <p>{t("privacy.text2-2")}</p>
              <p>{t("privacy.text2-3")}</p>
              <ul>
                <li>{t("privacy.bullet1")}</li>
                <li>{t("privacy.bullet2")}</li>
                <li>{t("privacy.bullet3")}</li>
                <li>{t("privacy.bullet4")}</li>
                <li>{t("privacy.bullet5")}</li>
                <li>{t("privacy.bullet6")}</li>
              </ul>
              <p>
                <br></br>
                {t("privacy.text2-4")}
              </p>
            </div>
          )}

          {lang.includes("_de") && (
            <div>
              <p>{t("privacy.text2-1")}</p>
              <p>{t("privacy.text2-2")}</p>
              <p>{t("privacy.text2-3")}</p>
              <ul>
                <li>{t("privacy.bullet1")}</li>
                <li>{t("privacy.bullet2")}</li>
                <li>{t("privacy.bullet3")}</li>
                <li>{t("privacy.bullet4")}</li>
                <li>{t("privacy.bullet5")}</li>
                <li>{t("privacy.bullet6")}</li>
              </ul>
              <p>
                <br></br>
                {t("privacy.text2-4")}
              </p>
            </div>
          )}

          <h4>{t("privacy.heading3")}</h4>
          <p>{t("privacy.text3")}</p>

          {lang.includes("_at") && (
            <div>
              <ol>
                <li>
                  <strong>{t("privacy.text3-1-1")}</strong>
                  <br></br>
                  {t("privacy.text3-1-2")}
                </li>
                <li>
                  <strong>{t("privacy.text3-2-1")}</strong>
                  <br></br>
                  {t("privacy.text3-2-2")}
                </li>
                <li>
                  <strong>{t("privacy.text3-3-1")}</strong>
                  <br></br>
                  {t("privacy.text3-3-2")}
                </li>
                <li>
                  <strong>{t("privacy.text3-4-1")}</strong>
                  <br></br>
                  {t("privacy.text3-4-2")}
                </li>
              </ol>
            </div>
          )}

          {lang.includes("_de") && (
            <div>
              <ol>
                <li>
                  <strong>{t("privacy.text3-1-1")}</strong>
                  <br></br>
                  {t("privacy.text3-1-2")}
                </li>
                <li>
                  <strong>{t("privacy.text3-2-1")}</strong>
                  <br></br>
                  {t("privacy.text3-2-2")}
                </li>
                <li>
                  <strong>{t("privacy.text3-3-1")}</strong>
                  <br></br>
                  {t("privacy.text3-3-2")}
                </li>
                <li>
                  <strong>{t("privacy.text3-4-1")}</strong>
                  <br></br>
                  {t("privacy.text3-4-2")}
                </li>
              </ol>
            </div>
          )}

          <h4>{t("privacy.heading4")}</h4>
          <p>{t("privacy.text4")}</p>

          {lang.includes("_at") && (
            <div>
              <p>{t("privacy.text4-1")}</p>
            </div>
          )}

          {lang.includes("_de") && (
            <div>
              <p>{t("privacy.text4-1")}</p>
            </div>
          )}

          <h4>{t("privacy.heading5")}</h4>
          <p>{t("privacy.text5")}</p>

          {lang.includes("_at") && (
            <div>
              <ol>
                <li>
                  <strong>{t("privacy.text5-1-1")}</strong>
                  {t("privacy.text5-1-2")}
                </li>
                <li>
                  <strong>{t("privacy.text5-2-1")}</strong>
                  {t("privacy.text5-2-2")}
                </li>
                <li>
                  <strong>{t("privacy.text5-3-1")}</strong>
                  {t("privacy.text5-3-2")}
                </li>
              </ol>
              <p>{t("privacy.text5-4")}</p>
            </div>
          )}

          {lang.includes("_de") && (
            <div>
              <ol>
                <li>
                  <strong>{t("privacy.text5-1-1")}</strong>
                  {t("privacy.text5-1-2")}
                </li>
                <li>
                  <strong>{t("privacy.text5-2-1")}</strong>
                  {t("privacy.text5-2-2")}
                </li>
                <li>
                  <strong>{t("privacy.text5-3-1")}</strong>
                  {t("privacy.text5-3-2")}
                </li>
              </ol>
              <p>{t("privacy.text5-4")}</p>
            </div>
          )}

          {lang.includes("_at") && (
            <div>
              <h4>{t("privacy.heading6")}</h4>
              <p>{t("privacy.text6-1")}</p>
              <ol>
                <li>
                  <strong>{t("privacy.text6-1-1")}</strong>
                  {t("privacy.text6-1-2")}
                </li>
                <li>
                  <strong>{t("privacy.text6-2-1")}</strong>
                  {t("privacy.text6-2-2")}
                </li>
                <li>
                  <strong>{t("privacy.text6-3-1")}</strong>
                  {t("privacy.text6-3-2")}
                </li>
                <li>
                  <strong>{t("privacy.text6-4-1")}</strong>
                  {t("privacy.text6-4-2")}
                </li>
                <li>
                  <strong>{t("privacy.text6-5-1")}</strong>
                  {t("privacy.text6-5-2")}
                </li>
                <li>
                  <strong>{t("privacy.text6-6-1")}</strong>
                  {t("privacy.text6-6-2")}
                </li>
                <li>
                  <strong>{t("privacy.text6-7-1")}</strong>
                  {t("privacy.text6-7-2")}
                </li>
                <li>
                  <strong>{t("privacy.text6-8-1")}</strong>
                  {t("privacy.text6-8-2")}
                </li>
                <li>
                  <strong>{t("privacy.text6-9-1")}</strong>
                  {t("privacy.text6-9-2")}
                </li>
              </ol>
              <ul>
                <li>{t("privacy.text6-10-1")}</li>
                <li>{t("privacy.text6-10-2")}</li>
                <li>{t("privacy.text6-10-3")}</li>
                <li>{t("privacy.text6-10-4")}</li>
                <li>{t("privacy.text6-10-5")}</li>
                <li>{t("privacy.text6-10-6")}</li>
                <li>{t("privacy.text6-10-7")}</li>
                <li>{t("privacy.text6-10-8")}</li>
              </ul>
              <p>{t("privacy.text6-11")}</p>
              <p>{t("privacy.text6-12")}</p>
              <h4>{t("privacy.heading7")}</h4>
              <p>{t("privacy.text7-1")}</p>
              <p>{t("privacy.text7-2")}</p>
              <ol>
                <li>
                  <strong>{t("privacy.text7-3-1")}</strong>
                  <br></br>
                  {t("privacy.text7-3-2")}
                </li>
                <li>
                  <strong>{t("privacy.text7-4-1")}</strong>
                  <br></br>
                  {t("privacy.text7-4-2")}
                </li>
                <li>
                  <strong>{t("privacy.text7-5-1")}</strong>
                  <br></br>
                  {t("privacy.text7-5-2")}
                </li>
                <li>
                  <strong>{t("privacy.text7-6-1")}</strong>
                  <br></br>
                  {t("privacy.text7-6-2")}
                </li>
              </ol>
              <p>{t("privacy.text7-7")}</p>
              <h4>{t("privacy.heading8")}</h4>
              <p>{t("privacy.text8-1")}</p>
              <p>{t("privacy.text8-2")}</p>
              <p>{t("privacy.text8-3")}</p>
              <li>
                <strong>session_id (Odoo): </strong>
                {t("privacy.text8-4-1")}
              </li>
              <li>
                <strong>fileToken (Odoo): </strong>
                {t("privacy.text8-4-2")}
              </li>

              <h4>{t("privacy.heading9")}</h4>
              <p>{t("privacy.text9-1")}</p>
              <p>{t("privacy.text9-2")}</p>
              <p>
                <strong>{t("privacy.text9-3")}</strong>
              </p>
              <p>{t("privacy.text9-4")}</p>
              <p>{t("privacy.text9-5")}</p>
              <ul>
                <li>
                  <strong>im_livechat_previous_operator_pid (Odoo): </strong>
                  {t("privacy.text9-6")}
                </li>
                <li>
                  <strong>utm_campaign (Odoo): </strong>
                  {t("privacy.text9-7")}
                </li>
                <li>
                  <strong>utm_source (Odoo): </strong>
                  {t("privacy.text9-8")}
                </li>
                <li>
                  <strong>utm_medium (Odoo): </strong>
                  {t("privacy.text9-9")}
                </li>
              </ul>
              <p>
                <br></br>
                {t("privacy.text9-10")}
              </p>
              <p>{t("privacy.text9-11")}</p>
              <p>{t("privacy.text9-12")}</p>
              <ul>
                <li>
                  <strong>__gads (Google): </strong>
                  {t("privacy.text9-13")}
                </li>
                <li>
                  <strong>__gac (Google): </strong>
                  {t("privacy.text9-14")}
                </li>
              </ul>
              <p>
                <br></br>
                {t("privacy.text9-15-1")}
                <a href={t("privacy.text9-15-2")} target="_blank">
                  {t("privacy.text9-15-3")}
                </a>
              </p>
              <p>{t("privacy.text9-16")}</p>
              <ul>
                <li>
                  <strong>_ga (Google): </strong>
                  {t("privacy.text9-17")}
                </li>
                <li>
                  <strong>_gat (Google): </strong>
                  {t("privacy.text9-18")}
                </li>
                <li>
                  <strong>_gid (Google): </strong>
                  {t("privacy.text9-19")}
                </li>
                <li>
                  <strong>gac* (Google): </strong>
                  {t("privacy.text9-20")}
                </li>
              </ul>
              <p>
                <br></br>
                {t("privacy.text9-21")}
              </p>
              <ul>
                <li>{t("privacy.text9-22")}</li>
                <li>{t("privacy.text9-23")}</li>
                <li>{t("privacy.text9-24")}</li>
                <li>{t("privacy.text9-25")}</li>
                <li>{t("privacy.text9-26")}</li>
                <li>{t("privacy.text9-27")}</li>
                <li>{t("privacy.text9-28")}</li>
                <li>{t("privacy.text9-29")}</li>
              </ul>
              <p>
                <br></br>
                {t("privacy.text9-30")}
              </p>
              <p>{t("privacy.text9-31")}</p>
              <ul>
                <li>{t("privacy.text9-32")}</li>
                <li>{t("privacy.text9-33")}</li>
                <li>{t("privacy.text9-34")}</li>
                <li>{t("privacy.text9-35")}</li>
                <li>{t("privacy.text9-36")}</li>
              </ul>
              <h4>{t("privacy.heading10")}</h4>
              <ol>
                <li>
                  <strong>
                    {t("privacy.text10-1")}
                    <br></br>
                    <br></br>
                  </strong>
                  <ul>
                    <li>
                      <strong>{t("privacy.text10-1-1a")}</strong>
                      {t("privacy.text10-1-1b")}
                    </li>
                    <li>
                      <strong>{t("privacy.text10-1-2a")}</strong>
                      {t("privacy.text10-1-2b")}
                      <br></br>
                      <br></br>
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>
                    {t("privacy.text10-2a")}
                    <br></br>
                    <br></br>
                  </strong>
                  {t("privacy.text10-2b")}
                  <br></br>
                  <br></br>
                  <ul>
                    <li>
                      <strong>{t("privacy.text10-2-1a")}</strong>
                      {t("privacy.text10-2-1b")}
                    </li>
                    <li>
                      <strong>{t("privacy.text10-2-2a")}</strong>
                      {t("privacy.text10-2-2b")}
                    </li>
                    <li>
                      <strong>{t("privacy.text10-2-3a")}</strong>
                      {t("privacy.text10-2-3b")}
                    </li>
                    <li>
                      <strong>{t("privacy.text10-2-4a")}</strong>
                      {t("privacy.text10-2-4b")}
                    </li>
                    <li>
                      <strong>{t("privacy.text10-2-5a")}</strong>
                      {t("privacy.text10-2-5b")}
                    </li>
                    <li>
                      <strong>{t("privacy.text10-2-6a")}</strong>
                      {t("privacy.text10-2-6b")}
                    </li>
                    <li>
                      <strong>{t("privacy.text10-2-7a")}</strong>
                      {t("privacy.text10-2-7b")}
                    </li>
                    <li>
                      <strong>{t("privacy.text10-2-8a")}</strong>
                      {t("privacy.text10-2-8b")}
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>
                    {t("privacy.text10-3")}
                    <br></br>
                    <br></br>
                  </strong>
                  <p>{t("privacy.text10-3-1")}</p>
                  <ul>
                    <li>
                      <strong>{t("privacy.text10-3-2a")}</strong>
                      {t("privacy.text10-3-2b")}
                    </li>
                    <li>
                      <strong>{t("privacy.text10-3-3a")}</strong>
                      {t("privacy.text10-3-3b")}
                      <br></br>
                      <br></br>
                    </li>
                  </ul>
                </li>
                <p>
                  {t("privacy.text10-4")}
                  <a href="https://www.dsb.gv.at/" target="_blank">
                    https://www.dsb.gv.at/
                  </a>
                  .
                </p>
              </ol>
            </div>
          )}

{lang.includes("_de") && (
            <div>
              <h4>{t("privacy.heading6")}</h4>
              <p>{t("privacy.text6-1")}</p>
              <ol>
                <li>
                  <strong>{t("privacy.text6-1-1")}</strong>
                  {t("privacy.text6-1-2")}
                </li>
                <li>
                  <strong>{t("privacy.text6-2-1")}</strong>
                  {t("privacy.text6-2-2")}
                </li>
                <li>
                  <strong>{t("privacy.text6-3-1")}</strong>
                  {t("privacy.text6-3-2")}
                </li>
                <li>
                  <strong>{t("privacy.text6-4-1")}</strong>
                  {t("privacy.text6-4-2")}
                </li>
                <li>
                  <strong>{t("privacy.text6-5-1")}</strong>
                  {t("privacy.text6-5-2")}
                </li>
                <li>
                  <strong>{t("privacy.text6-6-1")}</strong>
                  {t("privacy.text6-6-2")}
                </li>
                <li>
                  <strong>{t("privacy.text6-7-1")}</strong>
                  {t("privacy.text6-7-2")}
                </li>
                <li>
                  <strong>{t("privacy.text6-8-1")}</strong>
                  {t("privacy.text6-8-2")}
                </li>
                <li>
                  <strong>{t("privacy.text6-9-1")}</strong>
                  {t("privacy.text6-9-2")}
                </li>
              </ol>
              <ul>
                <li>{t("privacy.text6-10-1")}</li>
                <li>{t("privacy.text6-10-2")}</li>
                <li>{t("privacy.text6-10-3")}</li>
                <li>{t("privacy.text6-10-4")}</li>
                <li>{t("privacy.text6-10-5")}</li>
                <li>{t("privacy.text6-10-6")}</li>
                <li>{t("privacy.text6-10-7")}</li>
                <li>{t("privacy.text6-10-8")}</li>
              </ul>
              <p>{t("privacy.text6-11")}</p>
              <p>{t("privacy.text6-12")}</p>
              <h4>{t("privacy.heading7")}</h4>
              <p>{t("privacy.text7-1")}</p>
              <p>{t("privacy.text7-2")}</p>
              <ol>
                <li>
                  <strong>{t("privacy.text7-3-1")}</strong>
                  <br></br>
                  {t("privacy.text7-3-2")}
                </li>
                <li>
                  <strong>{t("privacy.text7-4-1")}</strong>
                  <br></br>
                  {t("privacy.text7-4-2")}
                </li>
                <li>
                  <strong>{t("privacy.text7-5-1")}</strong>
                  <br></br>
                  {t("privacy.text7-5-2")}
                </li>
                <li>
                  <strong>{t("privacy.text7-6-1")}</strong>
                  <br></br>
                  {t("privacy.text7-6-2")}
                </li>
              </ol>
              <p>{t("privacy.text7-7")}</p>
              <h4>{t("privacy.heading8")}</h4>
              <p>{t("privacy.text8-1")}</p>
              <p>{t("privacy.text8-2")}</p>
              <p>{t("privacy.text8-3")}</p>
              <li>
                <strong>session_id (Odoo): </strong>
                {t("privacy.text8-4-1")}
              </li>
              <li>
                <strong>fileToken (Odoo): </strong>
                {t("privacy.text8-4-2")}
              </li>

              <h4>{t("privacy.heading9")}</h4>
              <p>{t("privacy.text9-1")}</p>
              <p>{t("privacy.text9-2")}</p>
              <p>
                <strong>{t("privacy.text9-3")}</strong>
              </p>
              <p>{t("privacy.text9-4")}</p>
              <p>{t("privacy.text9-5")}</p>
              <ul>
                <li>
                  <strong>im_livechat_previous_operator_pid (Odoo): </strong>
                  {t("privacy.text9-6")}
                </li>
                <li>
                  <strong>utm_campaign (Odoo): </strong>
                  {t("privacy.text9-7")}
                </li>
                <li>
                  <strong>utm_source (Odoo): </strong>
                  {t("privacy.text9-8")}
                </li>
                <li>
                  <strong>utm_medium (Odoo): </strong>
                  {t("privacy.text9-9")}
                </li>
              </ul>
              <p>
                <br></br>
                {t("privacy.text9-10")}
              </p>
              <p>{t("privacy.text9-11")}</p>
              <p>{t("privacy.text9-12")}</p>
              <ul>
                <li>
                  <strong>__gads (Google): </strong>
                  {t("privacy.text9-13")}
                </li>
                <li>
                  <strong>__gac (Google): </strong>
                  {t("privacy.text9-14")}
                </li>
              </ul>
              <p>
                <br></br>
                {t("privacy.text9-15-1")}
                <a href={t("privacy.text9-15-2")} target="_blank">
                  {t("privacy.text9-15-3")}
                </a>
              </p>
              <p>{t("privacy.text9-16")}</p>
              <ul>
                <li>
                  <strong>_ga (Google): </strong>
                  {t("privacy.text9-17")}
                </li>
                <li>
                  <strong>_gat (Google): </strong>
                  {t("privacy.text9-18")}
                </li>
                <li>
                  <strong>_gid (Google): </strong>
                  {t("privacy.text9-19")}
                </li>
                <li>
                  <strong>gac* (Google): </strong>
                  {t("privacy.text9-20")}
                </li>
              </ul>
              <p>
                <br></br>
                {t("privacy.text9-21")}
              </p>
              <ul>
                <li>{t("privacy.text9-22")}</li>
                <li>{t("privacy.text9-23")}</li>
                <li>{t("privacy.text9-24")}</li>
                <li>{t("privacy.text9-25")}</li>
                <li>{t("privacy.text9-26")}</li>
                <li>{t("privacy.text9-27")}</li>
                <li>{t("privacy.text9-28")}</li>
                <li>{t("privacy.text9-29")}</li>
              </ul>
              <p>
                <br></br>
                {t("privacy.text9-30")}
              </p>
              <p>{t("privacy.text9-31")}</p>
              <ul>
                <li>{t("privacy.text9-32")}</li>
                <li>{t("privacy.text9-33")}</li>
                <li>{t("privacy.text9-34")}</li>
                <li>{t("privacy.text9-35")}</li>
                <li>{t("privacy.text9-36")}</li>
              </ul>
              <h4>{t("privacy.heading10")}</h4>
              <ol>
                <li>
                  <strong>
                    {t("privacy.text10-1")}
                    <br></br>
                    <br></br>
                  </strong>
                  <ul>
                    <li>
                      <strong>{t("privacy.text10-1-1a")}</strong>
                      {t("privacy.text10-1-1b")}
                    </li>
                    <li>
                      <strong>{t("privacy.text10-1-2a")}</strong>
                      {t("privacy.text10-1-2b")}
                      <br></br>
                      <br></br>
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>
                    {t("privacy.text10-2a")}
                    <br></br>
                    <br></br>
                  </strong>
                  {t("privacy.text10-2b")}
                  <br></br>
                  <br></br>
                  <ul>
                    <li>
                      <strong>{t("privacy.text10-2-1a")}</strong>
                      {t("privacy.text10-2-1b")}
                    </li>
                    <li>
                      <strong>{t("privacy.text10-2-2a")}</strong>
                      {t("privacy.text10-2-2b")}
                    </li>
                    <li>
                      <strong>{t("privacy.text10-2-3a")}</strong>
                      {t("privacy.text10-2-3b")}
                    </li>
                    <li>
                      <strong>{t("privacy.text10-2-4a")}</strong>
                      {t("privacy.text10-2-4b")}
                    </li>
                    <li>
                      <strong>{t("privacy.text10-2-5a")}</strong>
                      {t("privacy.text10-2-5b")}
                    </li>
                    <li>
                      <strong>{t("privacy.text10-2-6a")}</strong>
                      {t("privacy.text10-2-6b")}
                    </li>
                    <li>
                      <strong>{t("privacy.text10-2-7a")}</strong>
                      {t("privacy.text10-2-7b")}
                    </li>
                    <li>
                      <strong>{t("privacy.text10-2-8a")}</strong>
                      {t("privacy.text10-2-8b")}
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>
                    {t("privacy.text10-3")}
                    <br></br>
                    <br></br>
                  </strong>
                  <p>{t("privacy.text10-3-1")}</p>
                  <ul>
                    <li>
                      <strong>{t("privacy.text10-3-2a")}</strong>
                      {t("privacy.text10-3-2b")}
                    </li>
                    <li>
                      <strong>{t("privacy.text10-3-3a")}</strong>
                      {t("privacy.text10-3-3b")}
                      <br></br>
                      <br></br>
                    </li>
                  </ul>
                </li>
                <p>
                  {t("privacy.text10-4")}
                  <a href="https://www.dsb.gv.at/" target="_blank">
                    https://www.dsb.gv.at/
                  </a>
                  .
                </p>
              </ol>
            </div>
          )}


        </div>
      </div>
    </div>
  );
};

export default LegalNotice;
