import React, { useEffect, useState } from "react";
import "./ElSelfsuf.css";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import Cardtso from "./Elements/Cardtso";
import Cardcompanies from "./Elements/Cardcompanies";
import Cardstar from "./Elements/Cardstar";
import VideoLower from "./Elements/VideoLower";
import { NavLink } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
const ElSelfsuf = () => {
    const { t } = useTranslation();
    var lang = i18n.language;

    document.title = t("page-title.elselfsuf");

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalImageSrc, setModalImageSrc] = useState('');

    const handleImageClick = (src) => {
        setModalImageSrc(src);
        setIsModalVisible(true);
    };

    const handleCloseModal = () => {
        setIsModalVisible(false);
        setModalImageSrc('');
    };

    return (
        <div className="UIElsupply">
            <div className="container-fluid background">
                <div className="bg-image">
                    <VideoLower />
                </div>
                <div className="container">
                    <div className="heading">
                        <h1>{t("pages.elselfsuf")}</h1>
                        <h2 dangerouslySetInnerHTML={{ __html: t("elsupply.nds-smalltitle") }}></h2>
                    </div>
                </div>
            </div>
            <div className="container hero-image">
                <img
                    src={`${process.env.PUBLIC_URL}/images/products/elselfsuf.png`}
                    alt=""
                    className="starter-whatwedo"
                />
            </div>
            <div className="overlay">
                <img src={`${process.env.PUBLIC_URL}/images/w-overlay-tr.svg`} alt="" />
            </div>



            <div className="container content">
                <div className="text">
                    <p dangerouslySetInnerHTML={{ __html: t("elsupply.elselfsuf.text_1") }} className="text-center"></p>
                    <p dangerouslySetInnerHTML={{ __html: t("elsupply.elselfsuf.text_2") }} className="text-center"></p>
                </div>
            </div>

            <div className="container">
                <div className="heading mb-3">
                    <h3>{t("elsupply.elselfsuf.title_1")}</h3>
                </div>
                <div className="functions d-flex flex-row justify-content-between">
                    <div className="function d-flex flex-column justify-content-between align-items-start">
                        <div className="function_number"><span>1</span></div>
                        <div className="function_title heading">
                            <h4>{t("elsupply.elselfsuf.card_title_1")}</h4>
                            <p>{t("elsupply.elselfsuf.card_text_1")}</p></div>
                        <HashLink to={t('url.novadobasamooskrbe') + '#soo'} className="btn btn-secondary" exact>{t('products.readmore')} <i class="fa-solid fa-down-long mx-2"></i></HashLink>
                    </div>
                    <div className="function d-flex flex-column justify-content-between align-items-start">
                        <div className="function_number"><span>2</span></div>
                        <div className="function_title heading">
                            <h4>{t("elsupply.elselfsuf.card_title_2")}</h4>
                            <p>{t("elsupply.elselfsuf.card_text_2")}</p></div>
                        <HashLink to={t('url.novadobasamooskrbe') + '#hee'} className="btn btn-secondary" exact>{t('products.readmore')} <i class="fa-solid fa-down-long mx-2"></i></HashLink>
                    </div>
                    <div className="function d-flex flex-column justify-content-between align-items-start">
                        <div className="function_number"><span>3</span></div>
                        <div className="function_title heading">
                            <h4>{t("elsupply.elselfsuf.card_title_3")}</h4>
                            <p>{t("elsupply.elselfsuf.card_text_3")}</p></div>
                        <HashLink to={t('url.novadobasamooskrbe') + '#nds'} className="btn btn-secondary d-block" exact>{t('products.readmore')} <i class="fa-solid fa-down-long mx-2"></i></HashLink>
                    </div>
                </div>
            </div>

            <div className="container graybox">
                <div className="content text-center">
                    <img
                        className="img-fluid"
                        src={`${process.env.PUBLIC_URL}/images/samooskrba-netiranje.png`}
                        alt=""
                    />
                </div>
            </div>

            <div className="container my-5" id="soo">
                <div className="heading mb-3 text-center">
                    <h4>{t("elsupply.elselfsuf.nds_text")}</h4>
                    <h3>{t("elsupply.elselfsuf.title_3")}</h3>
                    <strong>{t("elsupply.elselfsuf.text_3")}</strong>
                </div>
                <div className="d-flex flex-row justify.content-between greycards mt-5">
                    <div className="heading greycard">
                        <h4>{t("elsupply.elselfsuf.smalltitle_3_1")}</h4>
                        <p>{t("elsupply.elselfsuf.text_3_1")}</p>
                    </div>
                    <div className="heading greycard">
                        <h4>{t("elsupply.elselfsuf.smalltitle_3_2")}</h4>
                        <p>{t("elsupply.elselfsuf.text_3_2")}</p>
                    </div>
                    <div className="heading greycard">
                        <h4>{t("elsupply.elselfsuf.smalltitle_3_3")}</h4>
                        <p>{t("elsupply.elselfsuf.text_3_3")}</p>
                    </div>
                    <div className="heading greycard">
                        <h4>{t("elsupply.elselfsuf.smalltitle_3_4")}</h4>
                        <p>{t("elsupply.elselfsuf.text_3_4")}</p>
                    </div>
                </div>
            </div>

            <div className="container my-5" id="hee">
                <div className="heading mb-3 text-center">
                    <h4>{t("elsupply.elselfsuf.nds_text")}</h4>
                    <h3>{t("elsupply.elselfsuf.title_4")}</h3>
                    <strong dangerouslySetInnerHTML={{ __html: t("elsupply.elselfsuf.text_4") }} className="text-center"></strong>
                </div>
                <div className="d-flex flex-row justify.content-between greycards mt-5">
                    <div className="heading greycard">
                        <h4>{t("elsupply.elselfsuf.smalltitle_4_1")}</h4>
                        <p dangerouslySetInnerHTML={{ __html: t("elsupply.elselfsuf.text_4_1") }}></p>
                    </div>
                    <div className="heading greycard">
                        <h4>{t("elsupply.elselfsuf.smalltitle_4_2")}</h4>
                        <p dangerouslySetInnerHTML={{ __html: t("elsupply.elselfsuf.text_4_2") }}></p>
                    </div>
                </div>
            </div>

            <div className="container my-5" id="nds">
                <div className="heading mb-3 text-center">
                    <h4>{t("elsupply.elselfsuf.nds_text")}</h4>
                    <h3>{t("elsupply.elselfsuf.title_5")}</h3>
                </div>
                <div className="row mt-5">
                    <div className="col-lg-6">
                        <p dangerouslySetInnerHTML={{ __html: t("elsupply.elselfsuf.text_5_1") }}></p>
                        <p dangerouslySetInnerHTML={{ __html: t("elsupply.elselfsuf.text_5_2") }}></p>
                        <ul>
                            <li dangerouslySetInnerHTML={{ __html: t("elsupply.elselfsuf.text_5_3") }}></li>
                            <li dangerouslySetInnerHTML={{ __html: t("elsupply.elselfsuf.text_5_4") }}></li>
                            <li dangerouslySetInnerHTML={{ __html: t("elsupply.elselfsuf.text_5_5") }}></li>
                            <li dangerouslySetInnerHTML={{ __html: t("elsupply.elselfsuf.text_5_6") }}></li>
                        </ul>
                    </div>
                    <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center">
                        <img
                            className="img-fluid"
                            src={`${process.env.PUBLIC_URL}/images/samooskrba-explain-2.png`}
                            alt=""
                        />
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="heading mb-3">
                    <h4>{t("elsupply.elselfsuf.nds_text")}</h4>
                    <h3>{t("elsupply.elselfsuf.title_6")}</h3>
                </div>
                <div className="functions d-flex flex-row justify-content-between">
                    <div className="function function50 d-flex flex-column justify-content-between align-items-start">
                        <div className="function_number"><span>1</span></div>
                        <div className="function_title heading">
                            <h4>{t("elsupply.elselfsuf.explain_title_1")}</h4>
                            <p dangerouslySetInnerHTML={{ __html: t("elsupply.elselfsuf.explain_text_1") }}></p></div>
                    </div>
                    <div className="function function50 d-flex flex-column justify-content-between align-items-start">
                        <div className="function_number"><span>2</span></div>
                        <div className="function_title heading">
                            <h4>{t("elsupply.elselfsuf.explain_title_2")}</h4>
                            <p dangerouslySetInnerHTML={{ __html: t("elsupply.elselfsuf.explain_text_2") }}></p></div>
                    </div>
                    <div className="function function50 d-flex flex-column justify-content-between align-items-start">
                        <div className="function_number"><span>3</span></div>
                        <div className="function_title heading">
                            <h4>{t("elsupply.elselfsuf.explain_title_3")}</h4>
                            <p dangerouslySetInnerHTML={{ __html: t("elsupply.elselfsuf.explain_text_3") }}></p></div>
                    </div>
                    <div className="function function50 d-flex flex-column justify-content-between align-items-start">
                        <div className="function_number"><span>4</span></div>
                        <div className="function_title heading">
                            <h4>{t("elsupply.elselfsuf.explain_title_4")}</h4>
                            <p dangerouslySetInnerHTML={{ __html: t("elsupply.elselfsuf.explain_text_4") }}></p></div>
                    </div>
                    <div className="function function50 d-flex flex-column justify-content-between align-items-start">
                        <div className="function_number"><span>5</span></div>
                        <div className="function_title heading">
                            <h4>{t("elsupply.elselfsuf.explain_title_5")}</h4>
                            <p dangerouslySetInnerHTML={{ __html: t("elsupply.elselfsuf.explain_text_5") }}></p></div>
                    </div>
                    <div className="function function50 d-flex flex-column justify-content-between align-items-start">
                        <div className="function_number"><span>6</span></div>
                        <div className="function_title heading">
                            <h4>{t("elsupply.elselfsuf.explain_title_6")}</h4>
                            <p dangerouslySetInnerHTML={{ __html: t("elsupply.elselfsuf.explain_text_6") }}></p></div>
                    </div>
                </div>
            </div>

            <div className="container files my-5">
                <div class="accordion accordion-flush" id="faqAccordion">
                    <div class="accordion-item">
                        <h4 class="accordion-header" id="q1">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#q1-collapse" aria-expanded="false" aria-controls="flush-collapseOne">
                                {t("elsupply.elselfsuf.q1")}
                            </button>
                        </h4>
                        <div id="q1-collapse" class="accordion-collapse collapse" aria-labelledby="q1" data-bs-parent="#faqAccordion">
                            <div class="accordion-body">
                                <p dangerouslySetInnerHTML={{ __html: t("elsupply.elselfsuf.a1_1") }}></p>
                                <strong dangerouslySetInnerHTML={{ __html: t("elsupply.elselfsuf.a1_2") }}></strong>
                                <ul>
                                    <li dangerouslySetInnerHTML={{ __html: t("elsupply.elselfsuf.a1_3") }}></li>
                                    <li dangerouslySetInnerHTML={{ __html: t("elsupply.elselfsuf.a1_4") }}></li>
                                    <li dangerouslySetInnerHTML={{ __html: t("elsupply.elselfsuf.a1_5") }}></li>
                                </ul>
                                <div className="row">
                                    <div className="col-md-6 d-flex flex-column justify-content-center">
                                        <strong dangerouslySetInnerHTML={{ __html: t("elsupply.elselfsuf.a1_6") }}></strong>
                                        <img
                                            className="img-fluid w-50 m-auto"
                                            src={`${process.env.PUBLIC_URL}/images/q1-image.png`}
                                            alt=""
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <img
                                            className="img-fluid popup"
                                            src={`${process.env.PUBLIC_URL}/images/samooskrba-explain.png`}
                                            alt=""
                                            onClick={() => handleImageClick(`${process.env.PUBLIC_URL}/images/samooskrba-explain.png`)}
                                            style={{ cursor: 'pointer' }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h4 class="accordion-header" id="q2">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#q2-collapse" aria-expanded="false" aria-controls="flush-collapseOne">
                                {t("elsupply.elselfsuf.q2")}
                            </button>
                        </h4>
                        <div id="q2-collapse" class="accordion-collapse collapse" aria-labelledby="q2" data-bs-parent="#faqAccordion">
                            <div class="accordion-body"><p dangerouslySetInnerHTML={{ __html: t("elsupply.elselfsuf.a2") }}></p></div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h4 class="accordion-header" id="q3">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#q3-collapse" aria-expanded="false" aria-controls="flush-collapseOne">
                                {t("elsupply.elselfsuf.q3")}
                            </button>
                        </h4>
                        <div id="q3-collapse" class="accordion-collapse collapse" aria-labelledby="q3" data-bs-parent="#faqAccordion">
                            <div class="accordion-body"><p dangerouslySetInnerHTML={{ __html: t("elsupply.elselfsuf.a3") }}></p></div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h4 class="accordion-header" id="q4">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#q4-collapse" aria-expanded="false" aria-controls="flush-collapseOne">
                                {t("elsupply.elselfsuf.q4")}
                            </button>
                        </h4>
                        <div id="q4-collapse" class="accordion-collapse collapse" aria-labelledby="q4" data-bs-parent="#faqAccordion">
                            <div class="accordion-body"><p dangerouslySetInnerHTML={{ __html: t("elsupply.elselfsuf.a4") }}></p></div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h4 class="accordion-header" id="q5">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#q5-collapse" aria-expanded="false" aria-controls="flush-collapseOne">
                                {t("elsupply.elselfsuf.q5")}
                            </button>
                        </h4>
                        <div id="q5-collapse" class="accordion-collapse collapse" aria-labelledby="q5" data-bs-parent="#faqAccordion">
                            <div class="accordion-body"><p dangerouslySetInnerHTML={{ __html: t("elsupply.elselfsuf.a5") }}></p></div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h4 class="accordion-header" id="q6">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#q6-collapse" aria-expanded="false" aria-controls="flush-collapseOne">
                                {t("elsupply.elselfsuf.q6")}
                            </button>
                        </h4>
                        <div id="q6-collapse" class="accordion-collapse collapse" aria-labelledby="q6" data-bs-parent="#faqAccordion">
                            <div class="accordion-body">
                                <p dangerouslySetInnerHTML={{ __html: t("elsupply.elselfsuf.a6_1") }}></p>
                                <p dangerouslySetInnerHTML={{ __html: t("elsupply.elselfsuf.a6_2") }}></p>
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h4 class="accordion-header" id="q7">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#q7-collapse" aria-expanded="false" aria-controls="flush-collapseOne">
                                {t("elsupply.elselfsuf.q7")}
                            </button>
                        </h4>
                        <div id="q7-collapse" class="accordion-collapse collapse" aria-labelledby="q7" data-bs-parent="#faqAccordion">
                            <div class="accordion-body"><p dangerouslySetInnerHTML={{ __html: t("elsupply.elselfsuf.a7") }}></p></div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h4 class="accordion-header" id="q8">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#q8-collapse" aria-expanded="false" aria-controls="flush-collapseOne">
                                {t("elsupply.elselfsuf.q8")}
                            </button>
                        </h4>
                        <div id="q8-collapse" class="accordion-collapse collapse" aria-labelledby="q8" data-bs-parent="#faqAccordion">
                            <div class="accordion-body">
                                <p dangerouslySetInnerHTML={{ __html: t("elsupply.elselfsuf.a8_1") }}></p>
                                <p dangerouslySetInnerHTML={{ __html: t("elsupply.elselfsuf.a8_2") }}></p>
                                <p dangerouslySetInnerHTML={{ __html: t("elsupply.elselfsuf.a8_3") }}></p>
                                <p dangerouslySetInnerHTML={{ __html: t("elsupply.elselfsuf.a8_4") }}></p>
                                <p dangerouslySetInnerHTML={{ __html: t("elsupply.elselfsuf.a8_5") }}></p>
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h4 class="accordion-header" id="q9">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#q9-collapse" aria-expanded="false" aria-controls="flush-collapseOne">
                                {t("elsupply.elselfsuf.q9")}
                            </button>
                        </h4>
                        <div id="q9-collapse" class="accordion-collapse collapse" aria-labelledby="q9" data-bs-parent="#faqAccordion">
                            <div class="accordion-body"><p dangerouslySetInnerHTML={{ __html: t("elsupply.elselfsuf.a9") }}></p></div>
                        </div>
                    </div>

                </div>
            </div>
            {isModalVisible && (
                <div className="modalbox" onClick={handleCloseModal}>
                    <div className="modalcontent">
                        <img src={modalImageSrc} alt="Popup" className="img-fluid" />
                    </div>
                    <button onClick={handleCloseModal}>X</button>
                </div>
            )}
        </div>
    );
};

export default ElSelfsuf;
